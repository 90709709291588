import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private route: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // manejar errores de red o del cliente
          console.log('error de red o cliente: ', error)
        } else {
          console.log('error de api: ', error)
          if(error.status === 401){
            this.route.navigateByUrl('/login')
            localStorage.clear()
          }
          // manejar errores de la API
        }
        return throwError(() => error);
      })
    );
  }
}
