import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormatCuit]'
})
export class FormatCuitDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = this.formatCuit(value);
    this.ngControl.control?.setValue(formattedValue, { emitEvent: false });
  }

  private formatCuit(value: string): string {
    // Remover todos los guiones
    const cuit = value.replace(/-/g, '');
    if (cuit.length <= 2) {
      return cuit;
    } else if (cuit.length <= 10) {
      return cuit.slice(0, 2) + '-' + cuit.slice(2);
    } else {
      return cuit.slice(0, 2) + '-' + cuit.slice(2, 10) + '-' + cuit.slice(10);
    }
  }

}
