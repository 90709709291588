import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Urban Estate Admin';
  status: OnlineStatusType = this.onlineStatusService.getStatus(); 
  alertConectionRef!:MatSnackBarRef<any>

  constructor(
    private onlineStatusService: OnlineStatusService,
    private snackBar: MatSnackBar
    ) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.status = status
      this.status ? this.closeAlertConection() : this.alertConection()
    });
  }

  alertConection() {
   this.alertConectionRef = this.snackBar.open('No estas conectado a internet, revise su conexión','', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['my-snackbar']
    });
  }

  closeAlertConection(){
    if (this.alertConectionRef) {
      this.alertConectionRef.dismiss()
    }
  }
}
