import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  from,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { log } from 'console';

const TOKEN = 'token';
const USER = 'user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);
  currentAccessToken: string | null = null;
  baseURL = environment.baseURL;

  constructor(private http: HttpClient, private router: Router) {
    this.loadToken();
  }

  loadToken() {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { email: string; clave: string }): Observable<any> {
    console.log(`🚀 ~ credentials:`, credentials);
    return this.http.post(`${this.baseURL}/login`, credentials).pipe(
      switchMap(({ token, usuario, scope }: any) => {
        this.currentAccessToken = token;
        const user = { ...usuario, scope };
        localStorage.setItem(TOKEN, token);
        localStorage.setItem(USER, JSON.stringify(user));
        return of(scope);
      }),
      tap((scope) => {
        this.isAuthenticated.next(true);
        scope === 'cliente'
          ? this.router.navigateByUrl('customer', { replaceUrl: true })
          : this.router.navigateByUrl('admin', { replaceUrl: true });
      })
    );
  }

  logout() {
    return this.http
      .post(`${this.baseURL}/logout`, {})
      .pipe(
        map((_) => {
          this.currentAccessToken = null;
          this.isAuthenticated.next(false);
          localStorage.clear();
          this.router.navigateByUrl('/auth/login', { replaceUrl: true });
          window.location.reload();
        })
      )
      .subscribe();
  }
}
