import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, finalize, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/service/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  protected token: string | null = localStorage.getItem('token');
  private _activeRequest: number = 0;

  constructor(
    private authService: AuthService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url = request.url.includes('-select');
    if (this._activeRequest === 0 && !url) {
      this.ngxUiLoaderService.start();
    }
    this._activeRequest++;

    // if (this.isInBlockedList(request.url)) {
    //   return next.handle(request);
    // }
    return next
      .handle(this.addHeaders(request))
      .pipe(finalize(() => this.stopLoader()));
  }

  private stopLoader() {
    this._activeRequest--;
    if (this._activeRequest === 0) {
      this.ngxUiLoaderService.stop();
    }
  }

  private isInBlockedList(url: string): Boolean {
    // Example: Filter out our login and logout API call
    if (url == `${environment.baseURL}/login`) {
      return true;
    } else {
      return false;
    }
  }

  private addHeaders(req: HttpRequest<any>) {
    if (this.authService.currentAccessToken) {
      let headers = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${this.authService.currentAccessToken}`);

      if (req.body instanceof FormData) {
        headers = headers.set('Content-Type', 'multipart/form-data');
      } else {
        headers = headers.set('Content-Type', 'application/json');
      }

      const clonedReq = req.clone({ headers });
      return clonedReq;
    } else {
      return req;
    }
  }
}
