<ngx-ui-loader 
blur = 8
delay = 0
fastFadeOut = true
fgsColor = "#b6ce0c"
fgsPosition = "center-center"
[fgsSize] = 50
fgsType = "three-bounce"
[gap] = 1
logoPosition = "center-center"
[logoSize] = 130
logoUrl = "assets/logo/live-logo.svg"
masterLoaderId = "master"
overlayBorderRadius = "0"
overlayColor = "rgba(40,40,40,0.98)"
pbColor = "#b6ce0c"
[pbThickness] = 3
[hasProgressBar] = true
text = ""
textColor = "#FFFFFF"
textPosition = "center-center"
maxTime = -1
minTime = 10
></ngx-ui-loader>
<router-outlet></router-outlet>